// initial
// ---------------------------------------------------------------------------------------------------

// overriding variables
$primary: #169998;
$black: #444444;
$info: #2171ff;
$info_light_1: #f0f7fd;
$feedback_card_background: #346F7D;

// custom variables
$app_cream_color_1: #e5e5e5;
$app_cream_color_2: #f3f3f3;

$app_border_1: 1px solid rgba(0, 0, 0, 0.2);
$app_border_2: 2px solid $black;
$app_border_3: 4px solid #fff;
$app_box_shadow_1: 0px 4px 4px rgba(0, 0, 0, 0.25);
$app_border_radius_1: 10px;

$app_extra_size_1: 2.2rem;
$app_extra_size_2: 2rem;
$app_extra_size_3: 0.7rem;
$app_extra_size_4: 1.8rem;

// font Variables
$app_font_1: 'Inter';
$app_font_2: 'Nunito';
$app_fall_back_font: 'sans-serif';
$app_font_bold_1: 600;
$app_font_bold_2: 300;
$app_font_bold_3: 700;

// navigation bar related
$navbarCustomBreakPoint: 384px;
$navbarLogoExtraHeight1: 30px; // normal
$navbarLogoExtraHeight2: 12px; // below `$navbarCustomBreakPoint`
$navbarLogoExtraHeight3: 70px; // spacing from page section 1st element to navbar | when navbar height is 0

$app_font_size_extra_1: 40px;
$app_between_sections_gap: 40px;

$feedback_card_width: 350px;

// base theme
@import '~bootstrap/scss/bootstrap';

// app level theme
@import './mixins';

// external fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;700');

// base
// ---------------------------------------------------------------------------------------------------

html,
body {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;

    background-color: $app_cream_color_1;
    color: $black !important;

    font-family: $app_font_1, $app_fall_back_font !important;
}

.app_inner_page_1 {
    padding-bottom: $app_extra_size_4 !important;
    padding-top: map-get($spacers, 3) !important;
}

// utilities
// ---------------------------------------------------------------------------------------------------

.me-4_5 {
    margin-right: $app_extra_size_1;
}

.py-4_5 {
    padding-top: $app_extra_size_1;
    padding-bottom: $app_extra_size_1;
}

.w-120px {
    width: 120px !important;
}

.w-100px {
    width: 100px !important;
}

.max-width-70{
    max-width: 70% !important;
}
.no_app_row_padded_columns_fix_1 {
    padding-top: map-get($spacers, 3);
}

.app_row_padded_columns_md {
    @include app_row_padded_columns(md);
}

.app_row_padded_columns_lg {
    @include app_row_padded_columns(lg);
}

.app_just_row_padded_columns {
    & > div[class^='col-'] {
        @include base_row_padded_columns;
    }
}

.app_line_spacing_1 {
    line-height: 26px !important;
}

.app_line_spacing_2 {
    line-height: 32px !important;
}

.app_font_weight_bold_1 {
    font-weight: $app_font_bold_1 !important;
}

.app_circle_1 {
    display: inline-block;
    border-radius: 50%;

    min-width: 20px;
    min-height: 20px;

    text-align: center;
    white-space: nowrap;

    line-height: 1;
    padding: 1px;

    box-sizing: content-box;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        padding-top: 100%;
        height: 0;
    }

    p {
        display: inline-block;
        vertical-align: middle;
    }
}

// typography
// ---------------------------------------------------------------------------------------------------

.app_title_1 {
    font-weight: $app_font_bold_1;
    font-size: 30px;
    font-family: $app_font_2, $app_fall_back_font !important;
    margin-bottom: map-get($spacers, 3);
}

.app_title_3 {
    font-weight: $app_font_bold_2;
    font-size: 20px;
    margin-bottom: map-get($spacers, 3);
}

.app_title_4 {
    font-size: 16px;
    margin-bottom: map-get($spacers, 3);
}

.app_text_1 {
    //font-size: 14px !important;
    font-size: 16px !important;
}

.app_text_2 {
    font-size: 12px !important;
}

.app_title_and_sub_title_1 {
    @include media-breakpoint-up(md) {
        max-width: 50%;
    }
}

.app_letter_spacing_1 {
    letter-spacing: 0.6px;
}

// components
// ---------------------------------------------------------------------------------------------------

.information_modal_1 {
    .modal-body {
        color: $black !important;
    }
}

.app_font_awesome_icon_1 {
    &.text-black {
        color: $black !important;
    }
}

.app_loadable_wrapper_1 {
    position: relative;

    .overlay {
        background: $app_cream_color_1;
        opacity: 0.6;
    }

    .app_loader_1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .overlay,
    .app_loader_1 {
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: 1;
    }
}

.app_progress_bar_1 {
    height: 4px;
    background-color: transparent !important;
}

.app_just_clickable_wrapper {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
}

.app_link {
    color: inherit !important;
    text-decoration: none;
}

.app_footer_1 {
    padding-top: map-get($spacers, 5);

    .app_footer_image_col{
        display: flex;
        align-items: center;
    }
    .app_footer_bottom {
        display: flex !important;
        justify-content: space-between !important;
        padding-top: map-get($spacers, 3) !important;
        border-top: $app_border_1;
    }
}

.app_image_icon_1 {
    @include common_image_attributes;
}

.app_image_icon_rounded_1 {
    @include common_image_attributes;
    border-radius: 50%;
}

.app_header_1 {
    $navigationBarHeight: 60px;
    padding-bottom: $navbarLogoExtraHeight1;

    @media only screen and (max-width: $navbarCustomBreakPoint) {
        padding-bottom: $navbarLogoExtraHeight2;
    }

    .app_navbar_1 {
        height: $navigationBarHeight;
        background: $white !important;

        .app_top_bar_logo_1 {
            background-color: $white;
            position: absolute;
            top: 0;
            padding: map-get($spacers, 3);
            box-shadow: $app_box_shadow_1;

            border-bottom-left-radius: $border-radius !important;
            border-bottom-right-radius: $border-radius !important;

            @media only screen and (max-width: $navbarCustomBreakPoint) {
                padding: map-get($spacers, 2);

                .app_image_icon_1 {
                    width: 110px !important;
                }
            }
        }

        .app_navbar_right {
            margin-left: auto;
            display: flex;

            p {
                margin-left: map-get($spacers, 4);
                margin-bottom: 0;
            }
        }
    }
}

.app_get_started_steps_block {
    display: flex;
    align-items: center;

    .app_steps_number_block {
        position: relative;

        .app_steps_number {
            position: absolute;
            bottom: -20px;
            left: 10px;
            font-size: $app_font_size_extra_1;
        }
    }
}

.app_button_1 {
    padding: $app_extra_size_3 map-get($spacers, 4);

    @include fix_bootstrap_button_outline;

    &.text_only_button_1 {
        background: none !important;
        border: none;
        padding-right: 0;
        padding-left: 0;

        &:focus,
        &:active,
        &:hover {
            color: inherit;
        }
    }

    &.btn-light {
        color: $primary;
    }

    &.btn-transparent {
        background: transparent;
        border-color: transparent;
    }

    &.icon_button {
        display: flex;
        align-items: center;
    }

    &.btn-size-sm {
        padding: map-get($spacers, 2);
    }
}

.app_button_no_focus {
    @include fix_bootstrap_button_outline;
}

.app_scroll_top_button{
    position: fixed;

    bottom: 20px;
    right: 20px;

    align-items: center;
    justify-content: center;

    z-index: 1000;

    cursor: pointer;
    transition: all 0.4s;
    animation: fadeIn 0.3s;

    opacity: 0.7;

    padding: map-get($spacers, 3);

    &:hover{
        opacity: 1;
    }
    &:focus{
        transform: scale(1.2);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}

.faqs_list_accordion {
    .accordion-item {
        background: none;
        margin-bottom: map-get($spacers, 3);
        border: none;

        &:last-child {
            margin-bottom: 0;
        }

        .accordion-header {
            .accordion-button {
                background: $primary;
                color: $white;

                box-shadow: none;
                border-top-left-radius: $app_border_radius_1 !important;
                border-top-right-radius: $app_border_radius_1 !important;

                &.collapsed {
                    background: $white;
                    color: $black;

                    border-bottom-left-radius: $app_border_radius_1 !important;
                    border-bottom-right-radius: $app_border_radius_1 !important;
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }

                &::after {
                    background: none !important;
                }
            }
        }

        .accordion-collapse {
            background: $primary;
            color: $white;

            border-bottom-left-radius: $app_border_radius_1 !important;
            border-bottom-right-radius: $app_border_radius_1 !important;

            .accordion-body {
                padding-top: 0;
            }
        }
    }
}

.app_case_study_card_container {
    .app_case_study_card {
        @include common_card_attributes;
    }

    .card_image {
        $image_dimension: 120px;

        width: $image_dimension;
        height: $image_dimension;

        border-radius: 50%;

        margin-bottom: map-get($spacers, 4);
    }
}

.app_landing_page_cta_card {
    width: 100%;
    background-color: $primary;
    border-radius: $app_border_radius_1;
    text-align: center;

    @include background_cover_image_div;

    @include media-breakpoint-up(md) {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .trigger_text {
        color: $white;
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(md) {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.app_decision_making_card_wrapper {
    .app_decision_making_card {
        @include common_card_attributes;

        .app_decision_making_card_body {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .app_decision_image_block {
            @include media-breakpoint-down(lg) {
                display: none;
            }

            .app_decision_image {
                max-height: 260px;
            }
        }
    }

    &.revered {
        .app_decision_making_card_body {
            flex-direction: row-reverse;
        }

        @include media-breakpoint-up(lg) {
            .app_decision_content_block {
                padding-left: map-get($spacers, 5);
            }
        }
    }

    &.not_revered {
        @include media-breakpoint-up(lg) {
            .app_decision_content_block {
                padding-right: map-get($spacers, 5);
            }
        }
    }
}

.app_single_data_model_card {
    @include common_card_attributes;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card_case_studies {
        display: flex;
        justify-content: center;
        margin-bottom: map-get($spacers, 1);

        .model_card_logo {
            width: 100px;
            margin: 0 map-get($spacers, 3);
            @include common_image_attributes;
        }
    }

    .model_card_footer {
        display: flex;
        justify-content: center;
        padding-top: map-get($spacers, 1);
    }
}

.app_feedback_card{
    @extend .app_single_data_model_card;
    text-align: center;
    color: $white;
    background-color: $feedback_card_background;
    width: $feedback_card_width;
    margin-left: auto;
}

.app_progress_tracker {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .app_progress_tracker_text {
        max-width: 180px;
        text-align: right;
        margin-bottom: 0;
        margin-right: map-get($spacers, 2);
    }
}

.app_wizard_option_card {
    height: 100%;

    &:hover {
        cursor: pointer;
    }

    &.disabled {
        background-color: $app_cream_color_1;
    }

    &.selected,
    &.not_disabled:hover {
        @include app_slow_transition_1;

        background-color: $primary;
        color: $white;

        svg {
            color: $white;
        }

        input,
        .app_radio_control {
            padding: 0.1rem;
            border: $app_border_2;
            border-color: $white !important ;
            background: radial-gradient($white 50%, rgba(255, 255, 255, 0) 20%);
            background-position: center;
        }
    }

    svg {
        color: $black;
    }

    .card-body {
        position: relative;

        .options_info_icon {
            position: absolute;
            top: 0;
            right: 0;
        }

        .options_question_icon{
            width: 50px;
            object-fit: contain;
            margin-right: map-get($spacers, 3);
        }
        .options_card_radio {
            display: flex;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .app_radio_control {
                display: block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: $app_border_2;
                transform: translateY(0.1em);
            }
        }
    }
}

.wizard_questions_tracker {
    // question group button
    .accordion-button {
        background: none;

        &.collapsed {
            color: $black;
        }

        @include fix_bootstrap_button_outline;
    }

    // the question and answers body
    .accordion-body {
        padding-bottom: map-get($spacers, 2);
        padding-top: map-get($spacers, 2);

        padding-left: 0;
        padding-right: 0;

        background: $app_cream_color_2;

        .question_section {
            cursor: pointer;

            padding-bottom: $app_extra_size_3;
            padding-top: $app_extra_size_3;

            padding-left: map-get($spacers, 3);
            padding-right: map-get($spacers, 3);

            // currently displayed question
            &.active,
            &:hover,
            &:active,
            &:focus {
                background-color: $white;
                @include app_slow_transition_1;
            }

            .number {
                @extend .app_circle_1;
                background: $gray-700;
                color: $white;

                margin-right: map-get($spacers, 3);

                max-height: 20px;
                padding: 2px;

                &.active {
                    background: $primary;
                }
            }

            .answer_body {
                display: flex;
                flex-wrap: wrap;

                align-items: center;
            }
        }
    }
}

.tags_holder {
    .badge {
        margin-bottom: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);

        border-radius: $border-radius !important;
        padding: map-get($spacers, 1) map-get($spacers, 2);
    }
}

.app_tooltip {
    max-width: 300px;
    background: white;
    box-shadow: $app_box_shadow_1;
    border: $app_border_1;

    .popover-header,
    .popover-body {
        background: $white;
        color: $black;
        border-radius: $app_border_radius_1;
    }

    .popover-arrow::after {
        border-top-color: $white;
    }

    .popover-arrow::before {
        display: none;
    }
}

.link_underline {
    text-decoration: underline;
    cursor: pointer;
}

// page wise styles
// ---------------------------------------------------------------------------------------------------

.page_section_1 {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 3);
}

.landing_hero_section {
    .left_section {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

    .right_section {
        text-align: center !important;
    }

    .actions_section {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-right: 0 !important;
            }
        }
    }
}

.get_started_steps_page_wrapper {
    @include media-breakpoint-down(md) {
        padding-top: map-get($spacers, 4);
    }

    .step {
        margin-bottom: map-get($spacers, 3);

        &:last-of-type {
            margin-bottom: map-get($spacers, 1);
        }
    }

    .get_started_link{
        padding-left: 0;
        padding-bottom: 0;

        color: $black;

        &:hover{
            color: $primary;
        }
    }
}

.wizard_based_questions_page {
    .page_bottom_actions,
    .page_top_actions {
        display: flex;
        align-items: center !important;

        .right_scroll_actions {
            margin-left: auto !important;
            display: flex;
        }
    }

    .error_alert_1 {
        margin-top: map-get($spacers, 3) !important;
        margin-bottom: map-get($spacers, 1) !important;
        p {
            margin-bottom: 0;
        }
    }
}

.individual_data_models_page {
    .landing_block {
        background: $info_light_1;
        padding-top: $navbarLogoExtraHeight3;
        padding-bottom: $app_between_sections_gap;

        @media only screen and (max-width: $navbarCustomBreakPoint) {
            padding-top: calc(
                #{$navbarLogoExtraHeight3} - (#{$navbarLogoExtraHeight1} - #{$navbarLogoExtraHeight2})
            );
        }

        .actions_holder {
            display: flex;
            flex-direction: column;
            width: fit-content;

            @include media-breakpoint-up(md) {
                margin-left: auto;
            }

            @include media-breakpoint-down(md) {
                margin: 0;
                width: 100%;

                button {
                    justify-content: center;
                }
            }
        }
    }

    .content_navigation_tab {
        background: $primary;

        .nav {
            // scroll
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;

            // display unify
            border: none;

            .nav-item {
                margin-right: map-get($spacers, 3);
            }

            .nav-link {
                transition: none;
                border: none;

                // spacing
                padding-top: map-get($spacers, 3);
                padding-bottom: map-get($spacers, 3);

                width: max-content;

                &.active,
                &:hover {
                    background: none;

                    border: none;
                    border-bottom: $app_border_3;
                    color: $white;

                    p {
                        color: $white !important;
                    }
                }
            }
        }
    }
    .tab_content_wrapper{
        background: $app_cream_color_2;

        .tab-content {
            padding-top: $app_between_sections_gap;
            padding-bottom: $app_between_sections_gap;
        }

        .html_content_container{
            font-family: $app_font_1 ,$app_fall_back_font !important;
        }
    }
}

.contact_page{
    margin: map-get($spacers, 5) 0;

    .contact_form{
        background: $white;

        padding: map-get($spacers, 5);
        margin-left: map-get($spacers, 5);

        border-radius: $app_border_radius_1;

        .form-group{
            margin: map-get($spacers, 5) 0;

            label{
                color: $primary;
                font-weight: $app_font_bold_1;
            }

            .form-control{
                border: none;
                border-radius: 0;
                border-bottom: $app_border_1;

                padding-left: 0;

                font-weight: $app_font_bold_1;

                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        @include media-breakpoint-down(md){
            margin: 0;
        }
    }
}
